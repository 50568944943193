<template>
  <GenericPage>
    <StoryblokComponent v-if="story" v-bind="componentProps" />
  </GenericPage>
</template>

<script setup lang="ts">
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import GenericPage from '@/components/pages/GenericPage.vue';
import useOrgId from '@/utils/useOrgId';
import { computed, type PropType } from 'vue';
import type { ISbStoryData } from '@storyblok/vue';

type AdditionalPropertiesFunction = <T>(story: ISbStoryData<T>) => Record<string, unknown>;

const props = defineProps({
  storyblokPath: { type: String, required: true },
  brandSpecificPage: { type: Boolean, default: false },
  additionalProperties: { type: Function as PropType<AdditionalPropertiesFunction> },
});

let path = props.storyblokPath;
if (props.brandSpecificPage) {
  path = `branding/${useOrgId().value}/${path}`;
}

const story = await useAsyncStoryblokWithI18n(path as string);
const componentProps = computed(() => ({
  id: story.value.id,
  uuid: story.value.uuid,
  blok: story.value.content,
  ...(props.additionalProperties ? props.additionalProperties(story.value) : {}),
}));
</script>
